<template>
  <div class="main">
    <div class="apply">
      <div class="title-box">
        <div class="title-box-item1">适用多种生鲜业态</div>
        <div class="title-box-item2">
          适应多种业态经营场景，让经营更加得心应手
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-1.png" alt="" />
            </div>
            <span>大型商超</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.1">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-2.png" alt="" />
            </div>
            <span>社区生鲜超市</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.2">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-3.png" alt="" />
            </div>
            <span>东北生鲜超市</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.3">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-4.png" alt="" />
            </div>
            <span>卤味熟食</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.4">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-5.png" alt="" />
            </div>
            <span>散称零食</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.5">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-6.png" alt="" />
            </div>
            <span>智慧农贸</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.6">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-7.png" alt="" />
            </div>
            <span>果蔬门店</span>
          </div>
          <div class="col-lg-3 apply-item wow bounceIn" data-wow-delay="0.7">
            <div class="apply-item-image">
              <img src="../assets/images/catering/fresh/2-8.png" alt="" />
            </div>
            <span>肉类冻品专卖</span>
          </div>
        </div>
      </div>
    </div>
    <!-- “鲜” 顾客买单的决定性因素  -->
    <div class="factor">
      <div class="title-box">
        <div class="title-box-item1">“鲜” 顾客买单的决定性因素</div>
        <div class="title-box-item2">要做到“鲜”，却被这些行业特性问题牵绊</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 factor-item">
            <div class="box">高额流通成本</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">易腐烂</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">高损耗</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">低客单价</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 factor-item">
            <div class="box">货架期短</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">价格波动大</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">季节性强</div>
          </div>
          <div class="col-lg-3 factor-item">
            <div class="box">地域性强</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授生鲜家族8大模块 突破层层难题 -->
    <div class="problem">
      <div class="title-box">
        <div class="title-box-item1">
          店教授生鲜家族8大模块 <br />突破层层难题
        </div>
      </div>
      <div class="container">
        <div class="problem-box">
          <div class="problem-box-item">
            <div class="top">入库盘点</div>
            <div class="bottom">
              <span>生鲜分拣系统</span>
              <span>智能盘点</span>
            </div>
          </div>
          <div class="problem-box-item">
            <div class="top">日常收银</div>
            <div class="bottom">
              <span>门店收银</span>
              <span>生鲜Al识别系统</span>
            </div>
          </div>
          <div class="problem-box-item">
            <div class="top">营销推广</div>
            <div class="bottom">
              <span>小程序&微店</span>
              <span>精准营销</span>
            </div>
          </div>
          <div class="problem-box-item">
            <div class="top">管理分析</div>
            <div class="bottom">
              <span>云后台</span>
              <span>移动管理</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 收银界面，高清大图 -->
    <div class="interface">
      <div class="title-box">
        <div class="title-box-item1">收银界面，高清大图</div>
        <div class="title-box-item2">客户端全新改版</div>
      </div>
      <div class="container">
        <div class="interface-image">
          <img src="../assets/images/catering/fresh/6-1.png" alt="" />
        </div>
        <div class="interface-box">
          <div class="interface-box-item">
            <div class="interface-box-item-top">凑整收银</div>
            <div class="interface-box-item-bottom">
              固定总价，循环读取变动的商品重量，凑满为止
            </div>
          </div>
          <div class="interface-box-item">
            <div class="interface-box-item-top">连续称重</div>
            <div class="interface-box-item-bottom">
              散货顺手叠加称重，事后一次性统一装袋
            </div>
          </div>
          <div class="interface-box-item">
            <div class="interface-box-item-top">商品预包装</div>
            <div class="interface-box-item-bottom">
              减少商品损耗，节省顾客排队称重的时间
            </div>
          </div>
          <div class="interface-box-item">
            <div class="interface-box-item-top">称重单位换算</div>
            <div class="interface-box-item-bottom">
              按千克收费、按斤收费、按两收费，由你定
            </div>
          </div>
          <div class="interface-box-item">
            <div class="interface-box-item-top">快速调价</div>
            <div class="interface-box-item-bottom">
              不囤不积，快速响应临期促销,降低生鲜损耗率
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 把控商品拆分制作全流程，精准计算损耗 -->
    <div class="process">
      <div class="title-box">
        <div class="title-box-item1">把控商品拆分制作全流程，精准计算损耗</div>
        <div class="title-box-item2">做你的门店省钱顾问</div>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="0s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-1.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-01.png" alt="" />
                试吃管理
              </div>
              <div class="process-item-right-content">
                专为试吃提供的管理工具，记录每一笔成本、损耗。
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="0.2s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-2.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-02.png" alt="" />
                组合制作
              </div>
              <div class="process-item-right-content">
                现做现卖，果切礼篮，保留制作明细，对账明了。
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="0.4s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-3.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-03.png" alt="" />
                商品混售
              </div>
              <div class="process-item-right-content">
                不同商品，成本不同，售价相同，共同称重销售。
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="0.6s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-4.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-04.png" alt="" />
                随时随地报损
              </div>
              <div class="process-item-right-content">
                不限设备（手机/手持机均可）、不限时、不限场景。
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="0.8s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-5.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-05.png" alt="" />
                多规格管理
              </div>
              <div class="process-item-right-content">
                一个商品可分为“250g”、“500g”等规格销售管理。开启多规格的扩展条码，自动完成单位换算、库存转化/统计。
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="1s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-6.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-06.png" alt="" />
                店教授高级损耗报表
              </div>
              <div class="process-item-right-content">
                可根据报损率区分出连锁分店的管理等级（标准/合格/未达标）。
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="1.2s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-7.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-07.png" alt="" />
                口味管理
              </div>
              <div class="process-item-right-content">
                一个商品多种口味，口味成本不同，售价不同。
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="1.4s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-8.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-08.png" alt="" />
                预警管理
              </div>
              <div class="process-item-right-content">
                库存预警，及时补货，预防断货。临期预警，及时清仓，降低损失。
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="1.6s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-9.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-09.png" alt="" />
                下发传秤
              </div>
              <div class="process-item-right-content">
                无缝对接条码秤，店教授收银端即可完成商品信息下发传秤。支持7位数与5位数条码。
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 process-item wow slideInLeft"
            data-wow-delay="1.8s"
          >
            <div class="process-item-left">
              <img src="../assets/images/catering/fresh/06-10.png" alt="" />
            </div>
            <div class="process-item-right">
              <div class="process-item-right-title">
                <img src="../assets/images/catering/fresh/6-10.png" alt="" />
                商品信息管理
              </div>
              <div class="process-item-right-content">
                批量快速上传品名、品类、库存等信息。店教授海量生鲜商品图库任用。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授生鲜AI识别系统 -->
    <div class="ai">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">店教授生鲜AI识别系统</div>
          <div class="title-box-item2">一眼识人，识品，识价</div>
        </div>
        <!-- <div class="ai-btn">
          <div class="ai-btn-item hover">AI称重收银方案</div>
          <div class="ai-btn-item">AI称重标签方案</div>
        </div> -->
        <!-- Swiper -->
        <div class="swiper-container ai-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="../assets/images/catering/fresh/07-2.png" alt="" />
            </div>
            <div class="swiper-slide">
              <img src="../assets/images/catering/fresh/07-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授生鲜分拣系统 -->
    <div class="sort">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">店教授生鲜分拣系统</div>
          <div class="title-box-item2">生鲜行业的后仓革新</div>
        </div>
        <div class="sort-image">
          <div class="sort-item">
            <div class="sort-item-left">01</div>
            <div class="sort-item-right">
              <span>覆盖分拣全流程</span>
              <span>提高仓库流转效率</span>
            </div>
          </div>
          <div class="sort-item">
            <div class="sort-item-left">02</div>
            <div class="sort-item-right">
              <span>步步操作有数据</span>
              <span>财务管理更准确</span>
            </div>
          </div>
          <div class="sort-item">
            <div class="sort-item-left">03</div>
            <div class="sort-item-right">
              <span>自动化操作</span>
              <span>节约人力成本更高效</span>
            </div>
          </div>
          <div class="sort-item">
            <div class="sort-item-left">04</div>
            <div class="sort-item-right">
              <span>无纸化操作</span>
              <span>降低过程中产生的损耗</span>
            </div>
          </div>
          <img src="../assets/images/catering/fresh/08.png" alt="" />
        </div>
        <div class="btn-box">
          <a class="sort-btn"
            >查看更多</a
          >
        </div>
      </div>
    </div>
    <!-- 店教授移动盘点系统 -->
    <div class="system">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">店教授移动盘点系统</div>
          <div class="title-box-item2">手机变身盘点机</div>
        </div>
        <div class="row">
          <div class="col-lg-6" style="width:538px;height:460px">
            <img src="../assets/images/catering/fresh/09_19.png" alt="" />
          </div>
          <div class="col-lg-6 system-right" style="width:585px;height:460px">
            <div class="num-box">
              <div class="num-box-item">
                <div class="num-box-item-left">01</div>
                <div class="num-box-item-right">整店&分区盘点</div>
              </div>
              <div class="num-box-item">
                <div class="num-box-item-left">02</div>
                <div class="num-box-item-right">随时建档新产品</div>
              </div>
              <div class="num-box-item">
                <div class="num-box-item-left">03</div>
                <div class="num-box-item-right">边营业边盘点</div>
              </div>
              <div class="num-box-item">
                <div class="num-box-item-left">04</div>
                <div class="num-box-item-right">计件盘点</div>
              </div>
              <div class="num-box-item">
                <div class="num-box-item-left">05</div>
                <div class="num-box-item-right">多人同时盘点</div>
              </div>
              <div class="num-box-item">
                <div class="num-box-item-left">06</div>
                <div class="num-box-item-right">称重盘点</div>
              </div>
            </div>
            <a
              class="system-btn"
            >
              查看更多</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 小程序&微店 -->
    <div class="applet">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">小程序&微店</div>
          <div class="title-box-item2">
            轻松拥有自己的线上商城，做自己的「生鲜到家」和「每日生鲜」
          </div>
        </div>
        <div class="swiper-container applet-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="applet-swiper-left">
                <img
                  src="../assets/images/catering/fresh/10-01_24.png"
                  alt=""
                />
              </div>
              <div class="applet-swiper-right" style="height:560px">
                <div class="introduce row">
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">01</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">
                        自定义商城模板
                      </div>
                      <div class="introduce-item-right-content">
                        打造自己的品牌小程序外卖
                      </div>
                    </div>
                  </div>
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">02</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">多样配送方式</div>
                      <div class="introduce-item-right-content">
                        商家自配送，随时可出动顺丰、达达，帮你解决压力
                      </div>
                    </div>
                  </div>
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">03</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">
                        实用电子会员中心
                      </div>
                      <div class="introduce-item-right-content">
                        拼团、砍价、签到、推
                        广、邀请有礼等，助力社交分享，创造裂变
                      </div>
                    </div>
                  </div>
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-image">
                      <!-- <img
                        src="../assets/images/catering/fresh/10-04.png"
                        alt=""
                      /> -->
                      <a
                        class="system-btn applet-btn-href"
                      >
                        查看更多</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="applet-swiper-left">
                <img src="../assets/images/catering/fresh/10-02.png" alt="" />
              </div>
              <div class="applet-swiper-right">
                <div class="introduce row">
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">01</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">以销定采</div>
                      <div class="introduce-item-right-content">
                        以销定采，省去仓储费用
                      </div>
                    </div>
                  </div>
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">02</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">招募社区团长</div>
                      <div class="introduce-item-right-content">
                        招募社区团长，降低配送和流量成本
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="applet-swiper-left">
                <img src="../assets/images/catering/fresh/10-03.png" alt="" />
              </div>
              <div class="applet-swiper-right">
                <div class="introduce row">
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">01</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">低成本获客</div>
                      <div class="introduce-item-right-content">
                        低成本获取私域领域流最，占领社区妈妈群
                      </div>
                    </div>
                  </div>
                  <div class="introduce-item col-xs-6">
                    <div class="introduce-item-left">
                      <div class="num">02</div>
                    </div>
                    <div class="introduce-item-right">
                      <div class="introduce-item-right-title">快速分享</div>
                      <div class="introduce-item-right-content">
                        微信直接转发，海报 分享，直播分享码…
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="applet-bottom">
          <div class="row">
            <div class="col-xs-6">
              <div class="applet-btn">
                <div class="applet-btn-item">线上商城</div>
                <div class="applet-btn-item">社区团购</div>
                <div class="applet-btn-item">小程序直播间</div>
              </div>
            </div>
            <div class="col-xs-6">
              <a
                class="system-btn applet-btn-href"
                href=""
              >
                查看更多</a
              >
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 会员权益+精准营销 -->
    <div class="member">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">会员权益+精准营销</div>
          <div class="title-box-item2">画像会员，激励消费产生更多裂变</div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="member-item wow flipInY" data-wow-delay="0s">
              <div class="member-item-title">会员基本权益</div>
              <div class="member-item-content">
                <div class="member-item-content-img">
                  <img src="../assets/images/catering/fresh/11-1.png" alt="" />
                </div>
                <div class="member-item-content-text">
                  多等级会员价/充值奖励/升级奖励•生日赠送积分兑礼/积分兑礼/积分抽奖/节日多倍积分
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="member-item wow flipInY" data-wow-delay="0.2s">
              <div class="member-item-title">店教授精准营销</div>
              <div class="member-item-content">
                <div class="member-item-content-img">
                  <img src="../assets/images/catering/fresh/11-2.png" alt="" />
                </div>
                <div class="member-item-content-text">
                  店员：筛选会员→编辑短信或礼包→选择时间→发送短信会员：看到短信，直接或延期参与活动或使用优惠
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="member-item wow flipInY" data-wow-delay="0.4s">
              <div class="member-item-title">社交分享，无限裂变</div>
              <div class="member-item-content">
                <div class="member-item-content-img">
                  <img src="../assets/images/catering/fresh/11-3.png" alt="" />
                </div>
                <div class="member-item-content-text">
                  电子会员中心：拼团砍价、签到有礼、邀请有礼
                  小程序直播、社区团购分享
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 巧用营销工具，提高商品连带率 -->
    <div class="tool">
      <div class="container">
        <div class="title-box">
          <div class="title-box-item1">巧用营销工具，提高商品连带率</div>
          <div class="title-box-item2">画像会员，激励消费产生更多裂变</div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="tool-item wow rotateInDownRight" data-wow-delay="0s">
              <div class="left">
                <img src="../assets/images/catering/fresh/12-1.png" alt="" />
              </div>
              <div class="right">
                <div class="title">FULL REDUCTION</div>
                <span>满额立减</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tool-item wow rotateInDownRight" data-wow-delay="0.2s">
              <div class="left">
                <img src="../assets/images/catering/fresh/12-2.png" alt="" />
              </div>
              <div class="right">
                <div class="title">PROMOTE SALES</div>
                <span>换购促销</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="tool-item wow rotateInDownRight" data-wow-delay="0.4s">
              <div class="left">
                <img src="../assets/images/catering/fresh/12-3.png" alt="" />
              </div>
              <div class="right">
                <div class="title">PROMOTIONAL GIFT</div>
                <span>促销搭赠</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tool-item wow rotateInDownRight" data-wow-delay="0.6s">
              <div class="left">
                <img src="../assets/images/catering/fresh/12-4.png" alt="" />
              </div>
              <div class="right">
                <div class="title">MINIMUM PRICE</div>
                <span>搭赠最低价</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授·云后台 -->
    <div class="cloud">
      <div class="title-box">
        <div class="title-box-item1">店教授·云后台</div>
        <div class="title-box-item2">
          围绕门店“订、销、产、配、财务”的一体化解决方案
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xs-4">
            <div class="cloud-item">
              <div class="cloud-item-img image-hd">
                <img src="../assets/images/catering/fresh/01.png" alt="" />
              </div>
              <div class="cloud-item-content">
                <div class="cloud-item-content-box">
                  <div class="title">云财务</div>
                  <div class="entry">
                    <div class="entry-item">
                      <span>出纳</span><span>报表账簿</span>
                    </div>
                    <div class="entry-item">
                      <span>凭证</span><span>基础设置</span>
                    </div>
                  </div>
                </div>
                <a
                  class="cloud-item-content-btn"
                  >查看更多</a
                >
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="cloud-item">
              <div class="cloud-item-img image-hd">
                <img src="../assets/images/catering/fresh/02.png" alt="" />
              </div>
              <div class="cloud-item-content">
                <div class="cloud-item-content-box">
                  <div class="title">供应链</div>
                  <div class="entry">
                    <div class="entry-item">
                      <span>全场景结算</span><span>合同管理</span>
                    </div>
                    <div class="entry-item">
                      <span>30+数据报表</span><span>批次管理</span>
                    </div>
                    <div class="entry-item">
                      <span>智能补货/订货</span><span>分仓发货</span>
                    </div>
                  </div>
                </div>
                <a
                  class="cloud-item-content-btn"
                  >查看更多</a
                >
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="cloud-item">
              <div class="cloud-item-img image-hd">
                <img src="../assets/images/catering/fresh/03.png" alt="" />
              </div>
              <div class="cloud-item-content">
                <div class="cloud-item-content-box">
                  <div class="title">数据BI</div>
                  <div class="entry">
                    <div class="entry-item">
                      <span>会员数</span><span>交易数据</span>
                    </div>
                    <div class="entry-item">
                      <span>商品数据</span><span>其他数据</span>
                    </div>
                  </div>
                </div>
                <a
                  class="cloud-item-content-btn"
                  >查看更多</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 精准数据分析,书写营业秘籍 -->
    <div class="data">
      <div class="title-box">
        <div class="title-box-item1">精准数据分析,书写营业秘籍</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="data-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/14-1.png" alt="" />
              </div>
              <div class="right">
                <div class="title">业绩分析</div>
                <span>哪一天业绩最好？<br />哪个时段销售最旺？</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="data-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/14-2.png" alt="" />
              </div>
              <div class="right">
                <div class="title">占比统计</div>
                <span>哪个产品是爆款？<br />支付宝、微信哪个用得多？</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="data-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/14-3.png" alt="" />
              </div>
              <div class="right">
                <div class="title">精准数据分析</div>
                <span>营运成本哪月增加最高？<br />同比增减有多少？</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="data-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/14-4.png" alt="" />
              </div>
              <div class="right">
                <div class="title">折让统计</div>
                <span
                  >国庆活动效果怎么样？<br />满减效果好还是打折效果好？</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授·移动管理 -->
    <div class="mobile">
      <div class="title-box">
        <div class="title-box-item1">店教授·移动管理</div>
        <div class="title-box-item2">店教授做你的门店隐形管家和强大后盾！</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="mobile-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/15-1.png" alt="" />
              </div>
              <div class="right">
                <div class="right-text">
                  <div class="title">店教授APP [我的店铺]</div>
                  <span>记录所有异常操作，视频 随时回放 </span>
                </div>
                <div class="right-tag">
                  <div class="right-tag-item">清空购物车</div>
                  <div class="right-tag-item">反结账</div>
                  <div class="right-tag-item">取消挂单</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mobile-item">
              <div class="left">
                <img src="../assets/images/catering/fresh/15-2.png" alt="" />
              </div>
              <div class="right">
                <div class="right-text">
                  <div class="title">店教授收银APP+<br />蓝牙电子秤</div>
                  <span>停电断网也能收银</span>
                </div>
                <div class="right-tag">
                  <div class="right-tag-item">数据互联</div>
                  <div class="right-tag-item">手机操作</div>
                  <div class="right-tag-item">更多功能</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店教授生鲜称重 你的同行都在用 -->
    <!-- <div class="weigh">
      <div class="title-box">
        <div class="title-box-item1">店教授生鲜称重 你的同行都在用</div>
      </div>
      <div class="container">
        <div class="swiper-container weigh-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-1.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">魔味鸭 卤味熟食</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text">
                      全国连锁370+门店，西北卤味熟食领先品牌，专注于
                      “中国湘味、汉味、川味经典鲜卤零食”的研发与生产，
                      在行业中率先获得国家CS食品安全管理体系认证。在
                      质量、服务、食品安全等方面不断与国际接轨，是现
                      代化熟食行业的先进典范。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text">
                      1.卤味熟食商品多口味、多规模，需要满足以上条件的
                      商品管理工具。2.门店常有不同商品、同一售价的情况
                      需要合理精准计算成本利润。
                    </div>
                    <div class="title">店教授方案：</div>
                    <div class="text">
                      1.采用店教授生鲜称重收银一体化解决方案，在称重收银过
                      程中，可使用连续称重工具，满足顾客购买多种商品，一次性称重称重的高效服务。
                      <br />2.为了解决不同商品、同一售价的情况，魔味鸭采用店教授
                      商品混售工具，精准计算混售利润。
                      <br />3.店教授商品管理可设置多口味、多规模，销售时选择相应
                      属性结账即可。<br />
                      4.为了拓宽线上线下销售渠道，同时避免外卖平台的高额
                      抽成，魔味鸭选择使用店教授小程序搭建外卖商城，实现多
                      渠道盈利。商品资料上传管理难的问题。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-2.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">槑的说 休闲食品</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text" style="margin-bottom: 50px">
                      全国160+家连锁散称休闲食品店，果食新文化传播者，品牌定位年轻化。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text" style="margin-bottom: 50px">
                      1.门店同时有按件、按重量销售的商品，库存与利润较繁杂。
                      <br />2.其次目标顾客群体多为青年人，对新兴营销活动敏感。
                    </div>
                    <div class="title">店教授方案：</div>
                    <div class="text" style="margin-bottom: 30px">
                      1.采用店教授生鲜称重收银一体化解决方案，通过商品预包装工具满足按件销售的商品需求，同时散称商品也能在前台一次性完成称重结账。<br />
                      2.采用店教授会员系统，通过微电子会员，一码多店通用，实现精准营销，拉新促活。
                      <br />
                      3.通过店教授生鲜称重后台管理系统，经营者可查看营业概况、财务报表、商品损耗，为其门店管理提供科学有依据的决策支持。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-3.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top" style="width: 490px">
                    濮阳县恒自然中心农贸市场
                  </div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text">
                      濮阳县恒自然中心农贸市场，由濮阳百佳置业有限公司建设，建筑面积13590.31㎡，共2栋数，农贸市场3层，仓储6层，目前开业一层，市场面积2000-3000㎡，预设60个收银摊位。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text">
                      1、农贸市场实现现代化管理，打造地区标杆和特色；<br />
                      2、摊位管理、数据管理、食品安全管理；<br />
                      3、搭建线上平台，手机下单可配送。
                    </div>
                    <div class="title">店教授方案：</div>
                    <div class="text">
                      1、
                      通过商贸管理平台、市场信息查询系统、商品溯源、农残检测、摊位管理、大数据显示屏，加强市场信息化管理水平、提高管理者监管经营市场的效率，以及信息的流通时效。强化生产经营者的质量安全意识，增加市场产品的来源透明度，消费者购买放心菜；解决传统零售端供求信息不充分、不准确、不连贯的问题。<br />
                      2、使用店教授智慧农贸O2O平台、会员营销系统，搭建小程序商城，将农贸市场搬到线上，形成集成农贸商城外卖平台。利用多种拓客拉新工具（如拼团、砍价、秒杀、等），深度会员画像，制定针对性的营销方案，一键投放至百万会员的微信。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-4.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">九品锅 火锅食材&器具</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text">
                      九品锅全国加盟连锁达1200+家，主营火锅食材和火锅器具，提供一站式火锅烧烤采购，创新购物模式。将火锅食材提前做好预包装，现场称重销售，立志让广大消费者在家就能吃上热辣的多风味火锅。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text">
                      1、连锁门店管理，总部能够看到各门店销售情况；<br />
                      2、线上线下一体化、商品需要预包装；<br />
                      3、加盟商订货高效便捷。
                    </div>
                    <div class="title">店教授方案：</div>
                    <div class="text">
                      1、
                      店教授提供四级连锁架构和财务数据管理架构，实现营业款到总部和到加盟商并行。为加盟分店提供商贸批发连锁架构服务，代客开单与小程序订货系都可并行。<br />
                      2、为其定制PC客户端，加强门店品牌化运营。商品预包装功能，尤其是食材独立包装，高效快速又卫生。<br />
                      3、通过店教授自建小程序，实现门店线上线下运营一体化的解决方案，保证加盟店品牌统一标准化，快速扩张招商加盟无压力，新增加盟店线上开店只需一分钟。外卖帮助九品锅在疫情期间拓宽了销售渠道。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-5.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">辣圈 火锅烧烤食材</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text">
                      辣圈以火锅烧烤食材为主，涵盖冻品，生鲜，小吃，底料蘸酱，四季饮品，火锅器具，烧烤器具等多种品类，并逐步向家庭食材迈进的一站式火锅烧烤食材超市连锁加盟系统。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text">
                      1、会员统一管理，会员充值到总部；<br />
                      2、需要开通线上业务，线上线下并行运营；<br />
                      3、加盟商线上订货，方便操作。
                    </div>
                    <div class="title">店教授方案：</div>
                    <div class="text">
                      1、店教授四级连锁架构和财务数据管理架构，实现营业数据到总部和到加盟商并行。其次，加盟门店独立收银，但会员充值到总部账户，消费由总部账户结算，实现连锁门店会员共享，塑造品牌影响力，目前已上线1237家。<br />
                      2、使用新供应链系统，实现加盟商订货需求。为加盟分店提供商贸批发连锁架构服务，订货通道多样化，前端订货、小程序订货、代客下单等多种模式并行。<br />
                      3、搭建小程序商城，线上线下共同运营。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-6.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">邵万生熟食 卤味熟食</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text" style="margin-bottom: 50px">
                      邵万生熟食地处上海南京东路，是一家具有161年悠久历史的中华老字号企业，以经营传统糟醉腌腊火腿、南北山珍海味干果及全国各地名优土特产及休闲食品为特色而久负盛名。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text" style="margin-bottom: 50px">
                      作为一家拥有百年历史的糟醉卤味品牌，不管是店内员工，还是消费群体，都是年龄层比较大的，收银系统要简单易操作，且需要满足连锁管理拓展及线上线下品牌销售的需求。
                    </div>
                    <div class="title">店教授解决方案：</div>
                    <div class="text" style="margin-bottom: 30px">
                      1、店教授生鲜称重PC版收银系统，界面简洁易操作；<br />
                      2、自建小程序，满足品牌线上线下管理，也是自有品牌建设的主要渠道。
                    </div>
                    <div class="title">方案实施效果：</div>
                    <div class="text" style="margin-bottom: 30px">
                      目前客户已上线29家门店，新店陆续上线中。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row">
                <div class="col-lg-5 left">
                  <img src="../assets/images/catering/fresh/18-7.jpg" alt="" />
                </div>
                <div class="col-lg-7 content">
                  <div class="title-top">果缤纷 水果店</div>
                  <div class="content-text">
                    <div class="title">品牌介绍：</div>
                    <div class="text">
                      长沙果缤纷商业管理有限责任公司以超前的水果品牌国际运营模式为先导，致力于将果缤纷打造成为中国水果行业知名的第三方管理服务机构。
                    </div>
                    <div class="title">客户需求：</div>
                    <div class="text">
                      1、加盟连锁门店管理，总部需要了解旗下加盟门店的整体营业情况；
                      <br />2、搭建线上渠道，线上线下共同运营 <br />
                      3、称重+收银一体
                    </div>
                    <div class="title">店教授解决方案：</div>
                    <div class="text">
                      1、店教授生鲜专业版收银软件+称重收银一体秤，前台称重即收银，方便快捷；
                      <br />
                      2、部分门店上线店教授生鲜小程序，充分利用线上流量优势，提高营业收入，同时也成为线下门店的获客利器。
                      <br />
                      3、实施效果：目前已上线门店530+，各项功能使用良好，7*24小时售后技术支持，客户满意度极高，后期新开门店将继续对接软硬件服务。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="container bottom-image">
        <img src="../assets/images/catering/fresh/16_84.png" alt="" />
      </div>
    </div> -->
    <!-- 新店开张，四种方案，装备一览 -->
    <div class="programme">
      <div class="title-box">
        <div class="title-box-item1">新店开张，四种方案，装备一览</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 programme-item">
            <div class="programme-item-image">
              <img src="../assets/images/catering/fresh/17-1.png" alt="" />
            </div>
            <div class="title">条码秤/电子秤+收银机</div>
            <div class="text">
              人工收银方案<br />
              大型商超、社区生鲜超市<br />
              东北模式生鲜超市等
            </div>
          </div>
          <div class="col-lg-3 programme-item">
            <div class="programme-item-image">
              <img src="../assets/images/catering/fresh/17-2.png" alt="" />
            </div>
            <div class="title">AI标签秤+自助收银</div>
            <div class="text">
              智能收银方案<br />
              大型商超、社区生鲜超市<br />
              东北模式生鲜超市等
            </div>
          </div>
          <div class="col-lg-3 programme-item">
            <div class="programme-item-image">
              <img src="../assets/images/catering/fresh/17-3.png" alt="" />
            </div>
            <div class="title">称重收银一体秤</div>
            <div class="text">
              社区生鲜超市、水果店<br />
              散称零售店、卤味熟食店<br />
              肉类专卖店、智慧农贸等
            </div>
          </div>
          <div class="col-lg-3 programme-item">
            <div class="programme-item-image">
              <img src="../assets/images/catering/fresh/17-4.png" alt="" />
            </div>
            <div class="title">AI收银秤</div>
            <div class="text">
              社区生鲜超市、水果店<br />
              智慧农贸等
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 开始体验 生鲜收银 -->
    <div class="experience">
      <div class="container">
        <div class="experience-title">开始体验<br />店教授生鲜称重收银</div>
        <div class="row">
          <div class="col-lg-10">
            <!-- //https://www.pospal.cn/downcenter.aspx  -->
            <a> 
              <span>电脑端下载</span>
              <img src="../assets/images/catering/fresh/xiazai.png" alt="" />
            </a>
            <a>
              <span>安卓端下载</span>
              <img src="../assets/images/catering/fresh/xiazai.png" alt="" />
            </a>
          </div>
          <div class="col-lg-2">
            <!-- <img src="../assets/images/catering/fresh/1.jpg" alt="" /> -->
          </div>
        </div>
        <div class="experience-bottom">
          <div class="left">
            <div class="experience-title">开始体验<br />店教授生鲜称重收银</div>
            <a
              >免费注册</a
            >
          </div>
          <div class="right">
            <div class="ewm-box">
              <!-- <img src="../assets/images/catering/fresh/2.png" alt="" /> -->
              <!-- <span>店教授生鲜视频号<br />扫码观看案例、功能演示</span> -->
            </div>
            <div class="ewm-box">
              <!-- <img src="../assets/images/catering/fresh/3.png" alt="" /> -->
              <!-- <span>店教授生鲜俱乐部 <br />扫码关注行业动态咨询</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "../../src/assets/css/plan/fresh/bootStrap.css";
import "../../src/assets/css/plan/fresh/fresh.css";
import "../../src/assets/css/plan/fresh/tw.css";
// import "../../src/assets/css/plan/fresh/swiper.css";
import { Swiper,Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
export default {
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    initSwiper() {
      Swiper.use([Navigation, Autoplay]);
      this.swiper = new Swiper(".filmswiper", {
        loop: true,
        speed: 600,
        spaceBetween: 21,
        preventClicksPropagation: false, //阻止默认事件
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 1000, //3秒切换一次
          pauseOnMouseEnter: false, //悬停停止切换
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }
      });
    },
  },
  mounted() {
    this.initSwiper();
  },
};
</script>

<style scoped>
.apply .container .row,
.factor .container .row{
  width:1200px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
}
.interface{
  width:1200px;
  margin: 0 auto;
}
.process .container .row{
  width:1200px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
}
.process .container .row .process-item-right{
  width: 400px;
  height: 200px;
}
.system,.applet,.member,
.tool,.cloud .container,
.data .container,.mobile .container,
.programme .container,.experience .container{
  width:1200px;
  margin: 0 auto;
}
.system .row{
  display: flex;
  justify-content: space-between;
}
.member .container .row,
.tool .container .row,
.cloud .container .row,
.data .container .row,
.mobile .container .row,
.programme .container .row{
  display: flex;
  justify-content: space-between;
}
</style>