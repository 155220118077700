<template>
  <div class="catering bbox positionr">
    <!-- banner -->
    <div class="bannerBox positionr">
      <img
        src="../../assets/images/catering/section_1.jpg"
        alt=""
        width="100%"
        class="banner"
      />
      <div class="banner_contB positiona hp100">
        <div class="w1200 flex align-center hp100">
          <div
            class="banner_cont animated bounceInLeft text-white"
            style="text-align: center"
          >
            <div class="f58" style="color: #ff6727">餐饮解决方案</div>
            <div class="f26 mt50" style="color: #000; line-height: 40px">
              <p>
                云蓝提供全业态餐饮解决方案，帮助众多知名餐饮商户实现数字化经营，
              </p>
              <p class="mt3">
                提高经营效率，并让餐饮商户更了解顾客，助力营销和经营决策。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tabbar -->
    <div class="bg-white tabbarBox">
      <div class="w1100 hp100 flex align-center">
        <div
          class="flex1 textc f16 pointer hp100"
          :class="[tabIndex == i ? 'text-blue bg-gray fw700' : '']"
          v-for="(item, i) in tabList"
          :key="i"
          @click="handleTba(i)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <!-- 智慧餐厅 -->
    <div class="animated bounceInLeft" v-if="tabIndex == 0">
      <!-- 标题 -->
      <div class="section section-2">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">连锁管理、区域管理</div>
            <div class="section-text-2 color-black mr30">多门店，轻管理</div>
            <div class="section-text-3 color-black">
              你可以利用店教授云后台，随时随地查看业务报表，分析生意
              <br />
              情况，并进行门店会员信息管理，对账以及订货等等。针对
              <br />
              大型连锁企业，更可使用区域管理功能进行权限划分、设置
              <br />
              区域总部等。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-3">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">外卖管理</div>
            <div class="section-text-2 color-black mr30">
              无缝对接3大外卖平台
            </div>
            <div class="section-text-3 color-black">
              前台收银深度集成美团、饿了么、百度外卖，你无需离开系
              <br />
              统，系统就能处理外卖订单，并直接对接厨打系统，再无需
              <br />
              抱着手机，前台后厨来回跑。店教授还提供专属客服一对一帮
              <br />
              您完成平台对接。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-4">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">微信店铺</div>
            <div class="section-text-2 color-black mr30">用餐高峰，</div>
            <div class="section-text-2 color-black mr30">无需增加人力</div>
            <div class="section-text-3 color-black">
              店教授微店支持“微信自助点餐”功能，顾客只需扫描桌面二
              <br />
              维码、或者登陆店教授微店，即可自助点餐，无需等待服务员
              <br />
              到场。同时，后厨根据点餐内容即可按桌号备餐，全程自动
              <br />
              化！帮助你实现高峰期客户分流，人力成本大大降低。
            </div>

            <div class="text-4 color-black">
              <a class="color-black" href="">了解店教授微店 ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-5">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-white">KDS电子厨显</div>
            <div class="section-text-2 color-white mr30">
              按时出菜，告别人为错单漏单
            </div>
            <div class="section-text-3 color-white">
              餐厅前台的点餐数据实时同步到后厨显示屏，后厨根据点餐内容即可按桌号备餐，服务
              <br />
              员也可用手机收到取菜通知，再不会出现因掉单漏单造成的麻烦。
              <br />
              同时，一线管理人员可以在屏幕上看到实时的后厨工作量、备餐时间，管理人员即可实时作出优化安排。
            </div>
            <div class="text-4">
              <a class="color-white" href="">了解更多 ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-6">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">营业报表</div>
            <div class="section-text-2 color-black mr30">餐饮专用报表，</div>
            <div class="section-text-2 color-black mr30">直指利润丢失根源</div>
            <div class="section-text-3 color-black">
              销售额－成本＝利润？在发现利润原地踏步时，或许应该看
              <br />
              看损耗。店教授收银系统支持菜品按原材料组合管理，能够让
              <br />
              菜消耗与原材料挂钩，帮您从源头管理门店成本。同时，你
              <br />
              可以利用菜品销售占比、口味销售占等多个维度比来衡量营
              <br />
              业状况，找出滞销菜品，并制定畅销组合。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-7">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-white">原材料管理</div>
            <div class="section-text-2 color-white mr30">
              多个门店，口味不变
            </div>
            <div class="section-text-3 color-white">
              通过标准化菜谱，模块化组合每一道菜。店教授收银系统支持
              <br />
              菜品按原材料组合管理，一道菜可以设置为由原材料或半成
              <br />
              品组成，并标记对应用量和单位，形成标准化菜谱，让您做
              <br />
              到口味标准化，厨师按配方烧菜，从而不必担心多门店口味
              <br />
              不一。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-8">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">供应链管理</div>
            <div class="section-text-2 color-black mr30">聪明订货，</div>
            <div class="section-text-2 color-black mr30">
              先一步预知原料消耗
            </div>
            <div class="section-text-3 color-black">
              使用店教授供应链管理功能，通过菜品消耗与原材料挂钩，你
              <br />
              就能直接预知库存消耗，从而及时订货。而当你有多家门店
              <br />
              或者多家供应商，店教授收银系统就能直接处理门店间的调货
              <br />
              和供应商配货，根据库存预警就能实现一键订货，更支持连
              <br />
              锁企业中央厨房模式！
            </div>
          </div>
        </div>
      </div>

      <div class="section section-9">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">会员精准营销</div>
            <div class="section-text-2 color-black mr30">精准的CRM，</div>
            <div class="section-text-2 color-black mr30">帮你找回更多顾客</div>
            <div class="section-text-3 color-black">
              玩转会员营销，无需羡慕大企业的CRM。现在您可以设置会
              <br />
              员积分制度，并根据不同的客户设置充值赠送、满额立减和
              <br />
              返现活动，实实在在回流更多资金。此外，店教授收银系统能
              <br />
              够帮您分析潜水会员，一键设置短信推送，利用系统内置的
              <br />
              促销功能实现激活。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-10">
        <div class="content">
          <div class="text">
            <div class="section-text-1 color-black">开放API</div>
            <div class="section-text-2 color-black mr30">开放API接口，</div>
            <div class="section-text-2 color-black mr30">
              轻松接入自己的系统
            </div>
            <div class="section-text-3 color-black">
              通过开放API，你可以轻松接入自己的会员系统、商城系统
              <br />
              等到店教授后台，一个系统就能管理你的业务，从而无需做大
              <br />
              规模的数据迁移，降低数据丢失和错乱的风险。
            </div>
          </div>
        </div>
      </div>

      <div class="section section-11">
        <div class="content">
          <div class="text">
            <div class="section-text-2 color-black mr30">
              专属金牌顾问，只为你服务
            </div>
            <div class="section-text-3 color-black">
              店教授金牌顾问团队拥有来自不同行业的咨询顾问，我们联合国际著名咨询
              <br />
              公司，帮助数万企业实现了管理的信息化。
              <br />
              现在，店教授企业版用户直接可以获得专属金牌服务，包含：1对1顾问支持、技
              <br />
              术支持、部署和迁移服务。
            </div>
          </div>
        </div>
      </div>

      <link rel="stylesheet" href="" />

      <div class="section case-section-1">
        <div class="content">
          <div class="text">
            <div class="text-1 color-black">12</div>
            <div class="text-2 color-black">10000</div>
            <div class="text-3 color-black">7×12</div>
          </div>
        </div>
      </div>

      <div class="section case-section-2">
        <div class="content">
          <div class="text">
            <div class="text-1 color-black">10000企业都在用</div>
            <div class="text-2 color-black">
              <a href="">查看所有案例 ></a>
            </div>
          </div>
        </div>
      </div>

      <div class="section section-13">
        <div class="content">
          <div class="text">
            <div class="text-1 color-orange">免费体验店教授企业版</div>

            <div class="text-2 text-2-1 color-gray">连锁管理</div>
            <div class="text-2 text-2-2 color-gray">区域管理</div>
            <div class="text-2 text-2-3 color-gray">对接外卖平台</div>
            <div class="text-2 text-2-4 color-gray">KDS电子厨显系统</div>
            <div class="text-2 text-2-5 color-gray">微信店铺</div>
            <div class="text-2 text-2-6 color-gray">会员管理</div>
            <div class="text-2 text-2-7 color-gray">精准CRM营销</div>
            <div class="text-2 text-2-8 color-gray">货流与供应链</div>

            <div class="text-3 text-3-1 color-gray">原材料管控</div>
            <div class="text-3 text-3-2 color-gray">支持中央工厂模式</div>
            <div class="text-3 text-3-3 color-gray">自定义企业Logo</div>
            <div class="text-3 text-3-4 color-gray">微信登录验证</div>
            <div class="text-3 text-3-5 color-gray">专属1对1技术支持</div>
            <div class="text-3 text-3-6 color-gray">系统部署、迁移服务</div>
            <div class="text-3 text-3-7 color-gray">支持开放API</div>

            <div>
              <a
                id="experience"
                class="experience color-white"
                >免费体验</a
              >
            </div>

            <div class="text-4 color-black">
              免费获取店教授金牌顾问演示，请拨打
              <span class="color-orange">400-066-0526</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧零售 -->
    <div class="animated bounceInLeft page2" v-if="tabIndex == 1">
      <!-- 标题 -->
      <!-- <div class="mt60 mb50">
        <div>
          <img
            src="../../assets/images/catering/arrow.png"
            alt=""
            width="20"
            class="vm"
          />
          <span class="f28 vm ml10">智慧零售解决方案</span>
        </div>
        <p class="mt25">
          基于传统零售门店，致力于推动线上线下一体化解决方案，为零售商家搭建小程序线上店，完成传统门店转型升级，从而达到门店、商品、库存、订单营销、会员等全面数字化经营。
        </p>
      </div> -->
      <!-- 列表 -->
      <!-- <div class="bbox list_2">
        <div
          class="item bg-white flex align-center transition"
          v-for="item in list2"
          :key="item.id"
        >
          <img :src="item.imgUrl" alt="" />
          <span class="ml25 f15">{{ item.tit }}</span>
        </div>
      </div> -->

      <!-- 标杆案例 -->
      <!-- <div class="pt60 pb60">
        <div class="f28 textc">标杆案例</div>
        <div class="flex align-center mt40">
          <div>
            <img
              src="../../assets/images/catering/zuihui_2/case.jpg"
              alt=""
              width="583"
              height="370"
            />
          </div>
          <div
            class="benchmark flex1 text-white flex flex-column justify-center"
          >
            <div class="ml60 mr50">
              <div class="f30">奥莱华联</div>
              <div class="bg-white benchmark_tip fw700 mt20 mb25">
                合作门店80家
              </div>
              <div class="f14">
                <span> 解决方案：软硬件一体解决方案</span>
              </div>
              <div class="f14">
                <span
                  >使用效果：聚合支付，收银高效便捷，数据报表实时同步，各门店经营状况实时监控；开通线上小程序门店，双重经营，库存管理实时跟踪库存状态，支持扫码入库，门店管理经营更便捷</span
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="section section_2">
        <div class="content">
          <div class="title">店教授收银快了解</div>
          <div class="divider_theme"></div>
          <div class="sub_title">前端收银，后台管理 双向运行，效果更佳</div>
          <div class="divider_gray" style="margin: 50px 0px 36px"></div>
          <div class="relative">
            <div class="detail_title">软件收银端</div>
            <div class="detail_describe">
              实在、好用，兼容多内容 提升台前顾客消费体验
            </div>
            <a class="btn_more absolute" href="" target="_black">MORE + </a>
          </div>
          <dl class="marked_list width_67">
            <dt>windows系统</dt>
            <dt>手机版店教授</dt>
            <dt>饿了么/美团接单</dt>
            <dt>Android系统</dt>
            <dt>电脑版店教授</dt>
            <dt>店教授微店接单</dt>
            <dt>iOS系统</dt>
            <dt>平板版店教授</dt>
            <dt>店教授小程序接单</dt>
          </dl>
          <div class="divider_gray" style="margin: 24px 0px 36px"></div>
          <div class="relative">
            <div class="detail_title">SaaS云后台</div>
            <div>有网络 有浏览器 在哪都能用 提高台后员工工作质量</div>
            <a class="btn_more absolute" href="" target="_black">MORE + </a>
          </div>
          <dl class="marked_list">
            <dt>库存管理</dt>
            <dt>会员管理</dt>
            <dt>网店管理</dt>
            <dt>员工管理</dt>
            <dt class="width_auto">连锁设置</dt>
            <dt>商品管理</dt>
            <dt>货流管理</dt>
            <dt>营销管理</dt>
            <dt>营业分析</dt>
          </dl>
          <div class="divider_gray" style="margin-top: 24px"></div>
        </div>
      </div>

      <div class="section section_3">
        <div class="content">
          <div class="question">Q：你家的便利店在卖什么？</div>
          <div class="question_detail">
            零食、烟酒、饮料、水果、冷冻品、奶制品、日用品、熟食、微波快餐？？<br />
            <span class="color_theme"
              >你清楚他们价格、库存和销量排名吗？？</span
            >
          </div>
          <div class="divider_theme" style="margin: 39px 0px 45px"></div>
          <div class="question">Q：你家的便利店怕的是什么？</div>
          <div class="question_detail">
            怕本地生活服务让顾客不再出门买东西？？怕高峰期长龙让顾客另选他家消费？<br />
            怕员工订货没经验，量多量少没依据，电话订货常忘事？？<br />
            怕同行都在升级服务，只有我落伍没竞争力？？
          </div>
        </div>
      </div>

      <div class="section section_4">
        <div class="content">
          <div class="title">店教授便利店解决方案</div>
          <div class="divider_theme"></div>
          <div class="sub_title">深入最小零售业态日常，打造更好门店管理</div>
          <div class="flex">
            <a href="">
              <div>区域连锁管理</div>
            </a>
            <a href="">
              <div>商品管理</div>
            </a>
            <a href="">
              <div>会员管理</div>
            </a>
            <a href="">
              <div>线上商城</div>
            </a>
            <a href="">
              <div>自助收银</div>
            </a>
            <a href="">
              <div>智能盘点</div>
            </a>
            <a href="">
              <div>订货小程序</div>
            </a>
            <a href="">
              <div>自动报货</div>
            </a>
            <a href="">
              <div>开放API</div>
            </a>
            <a href="">
              <div>数据报表</div>
            </a>
          </div>
          <div class="divider_gray"></div>
        </div>
      </div>

      <div class="section section_5" id="regionalManagement">
        <div class="content">
          <div class="title">店教授区域连锁管理</div>
          <div class="divider_theme"></div>
          <div class="sub_title">层级分明，操作简易，同步超快</div>
          <div class="graph relative">
            <div class="absolute" style="top: 16px; left: 601px">连锁总部</div>
            <div class="absolute" style="top: 108px; left: 623px">总店</div>
            <div class="absolute" style="top: 320px; left: 128px">
              分区A<br /><span class="sub_describe">分区一总账号</span>
            </div>
            <div class="absolute" style="top: 320px; left: 507px">
              分区B<br /><span class="sub_describe">分区一总账号</span>
            </div>
            <div class="absolute" style="top: 465px; left: 15px">直营店1</div>
            <div class="absolute" style="top: 465px; left: 224px">直营店2</div>
            <div class="absolute" style="top: 465px; left: 394px">加盟店1</div>
            <div class="absolute" style="top: 465px; left: 605px">加盟店2</div>
            <div class="absolute" style="top: 465px; left: 783px">直营店1</div>
            <div class="absolute" style="top: 465px; left: 1108px">直营店2</div>
          </div>
          <div class="divider_gray" style="margin: 64px 0px"></div>
          <div class="flex">
            <div>
              <div class="detail_title">几步点击，快速生成</div>
              <div class="detail_describe">
                商品信息、会员制度、供货商均可一键复制；一表部署子门店权限
              </div>
            </div>
            <div class="divider_gray_vertial"></div>
            <div>
              <div class="detail_title">会员、营销皆可共享</div>
              <div class="detail_describe">
                总部可设置子门店，会员可共享；总部创建营销活动可自由指派至门店执行
              </div>
            </div>
            <div class="divider_gray_vertial"></div>
            <div>
              <div class="detail_title">货流统一把控</div>
              <div class="detail_describe">
                总店统一执行各门店进货需求，实现门店间定向调货/配货，比对各门店货单货价
              </div>
            </div>
            <div class="divider_gray_vertial"></div>
            <div>
              <div class="detail_title">资金统一回流</div>
              <div class="detail_describe">
                统一配置各门店非现金收支账户，门店会员消费直达总部账户，保证直营体系资金安全
              </div>
            </div>
          </div>
          <div class="divider_gray" style="margin-top: 100px"></div>
        </div>
      </div>

      <div class="section section_6" id="productManagement">
        <div class="content">
          <div class="title">店教授商品管理</div>
          <div class="divider_theme"></div>
          <div class="sub_title">攻略全品类商品精细化管理需求</div>
          <div class="flex">
            <div>
              <div class="mask">
                <div class="detail_title">实用促销</div>
                <div class="detail_describe">
                  满减、打折、<br />特价、买赠、换购 ……
                </div>
              </div>
            </div>
            <div>
              <div class="mask">
                <div class="detail_title">一品多码</div>
                <div class="detail_describe">
                  同名称不同内容和口味的商品内设不同<br />条码，各自记录销量各自记录库存，合<br />并也可统计
                </div>
              </div>
            </div>
            <div>
              <div class="mask">
                <div class="detail_title">组装拆分</div>
                <div class="detail_describe">
                  大件单位的商品拆开包装<br />按小件单位来摆放来售卖<br />云后台自动减库存
                </div>
              </div>
            </div>
            <div>
              <div class="mask">
                <div class="detail_title">分类管理</div>
                <div class="detail_describe">
                  二级分类管理，规范商品逻辑<br />加快店员收银速度，细化商品<br />性质，利于数据分析
                </div>
              </div>
            </div>
            <div>
              <div class="mask">
                <div class="detail_title">批量导入</div>
                <div class="detail_describe">
                  海量商品信息，依次导入模板，直接上传<br />更新。比人工操作快了许多
                </div>
              </div>
            </div>
            <div>
              <div class="mask">
                <div class="detail_title">预包装打印</div>
                <div class="detail_describe">
                  生鲜商品提前分拣、称重、包装、打<br />码、贴标。收银员扫码就可直接收银
                </div>
              </div>
            </div>
          </div>
          <div class="divider_gray"></div>
        </div>
      </div>

      <div class="section section_7" id="customerManagement">
        <div class="content">
          <div class="title center">
            <span class="color_theme">店教授会员管理</span
            ><br />线上线下会员全打通<br />步步为营，培育忠诚客户
          </div>
          <div class="flex">
            <div>
              <div class="detail_title">所有会员</div>
              <div class="detail_describe">
                会员享特价-生日有礼物-指定日期有特惠<br />
                充值有赠送-消费有积分-积分可兑礼-积分抽奖<br />
                升级有奖励-越高级越优惠-参与门店限定活动
              </div>
            </div>
            <div>
              <div class="detail_title">微店会员</div>
              <div class="detail_describe">
                授权注册享新礼包-邀请好友得推荐礼包<br />
                参与拼团、团购、砍价-查看个人信息-签到得积分<br />
                活动时期，还可向多个会员发送营销短信或门店礼包
              </div>
            </div>
            <div>
              <div class="detail_title">人脸识别会员</div>
              <div class="detail_describe">
                系统快速识别人脸信息，查看会员余额及优惠<br />
                提前了解会员消费习惯，精准推荐喜好产品<br />
                无需出示会员卡和电子券，扫脸即可支付
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section section_8" id="onlineStore">
        <div class="content">
          <div class="title">线上商城</div>
          <div class="divider_theme"></div>
          <div class="sub_title">像天虹和永辉一样在手机上卖货</div>
          <div class="tab">
            <div
              class="tab_title tab_title_1 title_selected"
              @mousemove="(e) => changeTab(1,e)"
            >
              店教授微店
            </div>
            <div class="tab_title tab_title_2" @mousemove="(e) => changeTab(2,e)">
              店教授扫码购
            </div>
            <dl class="tab_content tab_content_1 content_selected flow_list">
              <dt class="highlight">多张滚动轮播图，点击跳转至商品详情</dt>
              <dt>首页内含优惠券，支持线上线下共同使用</dt>
              <dt>参与线上团购，未满人数及时退款</dt>
              <dt>限时砍价分享微信好友，优惠购买折扣商品</dt>
              <dt>邀请好友注册，双方均得余额或积分奖励</dt>
              <dt>成为店铺推广员，好友购买即可获得佣金奖励</dt>
              <dt>每日签到，获取积分</dt>
              <dt>积分抽奖，兑换优惠券或实物商品</dt>
              <dt>线上充值会员余额，线下使用电子会员码支付</dt>
            </dl>
            <dl class="tab_content tab_content_2 marked_list highlight">
              <dt>手机就是收银机</dt>
              <dt>一键直接手机结算</dt>
              <dt>边买边扫</dt>
              <dt>核销就可离场</dt>
              <a class="btn_more" href="" target="_black">MORE + </a>
              <video id="scanVideo" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
              <div class="video_mask" @click="playVideo()"></div>
            </dl>
          </div>
          <div class="divider_gray"></div>
        </div>
      </div>

      <div class="section section_9" id="selfServiceCash">
        <div class="content">
          <div class="title">自助收银-让顾客顺手为自己结算</div>
          <div class="divider_theme"></div>
          <div class="sub_title">店教授自助收银，哔哔几声就结束</div>

          <div class="graph_top relative">
            <div class="highlight" onmouseover="changeHighlight(event)">
              大屏自助收银机
            </div>
            <div onmouseover="changeHighlight(event)">MINI收银机</div>
            <div>微信/支付宝刷脸支付</div>
            <div>机器扫码支付</div>
          </div>
          <div class="graph_bottom relative">
            <div style="left: 60px">电子会员码（会员余额支付）</div>
            <div style="left: 535px">微信付款码</div>
            <div style="left: 943px">支付宝付款码</div>
          </div>
          <div class="tab">
            <div
              class="tab_title tab_title_1 title_selected"
              @mousemove="(e) => changeTab(1,e)"
            >
              大屏自助收银机
            </div>
            <div class="tab_title tab_title_2" @mousemove="(e) => changeTab(2,e)">
              MINI收银机
            </div>
            <dl class="tab_content tab_content_1 content_selected flow_list">
              <dt class="highlight">
                闲时播放视频或图片广告，提高门店营销宣传效率
              </dt>
              <dt>顾客自助扫描商品码购物，减少排队时间，优化购物体验</dt>
              <dt>登录会员可享受会员优惠价，支付后可获消费积分</dt>
              <dt>支持使用电子优惠券，一键刷码核销享受优惠</dt>
              <dt>多种支付方式满足多样化需求，不带手机钱包也可刷脸购物</dt>
            </dl>
            <dl class="tab_content tab_content_2 flow_list">
              <dt class="highlight">
                闲时播放视频或图片广告，小巧吸睛但宣传效果不减
              </dt>
              <dt>顾客自助扫描商品码，自主购物高效快捷</dt>
              <dt>热狗、关东煮等无码商品也能轻松结算</dt>
              <dt>扫描或输入电子优惠券码，一键核销享受优惠</dt>
              <dt>登录会员享受会员优惠价，点击余额支付一键完成付款</dt>
              <dt>语音播报收款详情，避免顾客错单漏单</dt>
            </dl>
          </div>
        </div>
      </div>

      <div class="section section_10" id="inventory">
        <div class="content">
          <div class="title">店教授智能盘点</div>
          <div class="divider_theme"></div>
          <div class="sub_title">随时随地开始盘点</div>
          <dl class="flex relative">
            <dt class="highlight" onmouseover="changeHighlight(event)">
              <span class="color_theme">01</span>
              <span>使用盘点机或手机开始盘点</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
            <dt onmouseover="changeHighlight(event)">
              <span class="color_theme">02</span>
              <span>登录店教授账号</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
            <dt onmouseover="changeHighlight(event)">
              <span class="color_theme">03</span>
              <span>创建盘点计划</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
            <dt onmouseover="changeHighlight(event)">
              <span class="color_theme">04</span>
              <span>核对盈亏表</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
            <dt onmouseover="changeHighlight(event)">
              <span class="color_theme">05</span>
              <span>盘点漏盘商品</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
            <dt onmouseover="changeHighlight(event)">
              <span class="color_theme">06</span>
              <span>提交结果</span>
              <span class="color_theme">&nbsp;▶</span>
            </dt>
          </dl>
        </div>
      </div>

      <div class="section section_11" id="miniProgram">
        <div class="content">
          <div class="title">店教授订货小程序</div>
          <div class="divider_theme"></div>
          <div class="sub_title">更快更智能的订货选择</div>
          <dl class="flow_list">
            <dt class="highlight">
              会员用手机号码+验证码登录小程序
              <video id="orderVideo1" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>不同等级会员享有不同商品采购价</dt>
            <dt>
              不同订货基数，小程序显示的商品价格也有所不同
              <video id="orderVideo3" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              点击商品图片，细看商品详情
              <video id="orderVideo4" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              内含商品优惠券，点击即可领取
              <video id="orderVideo5" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              商品可分享，可转发至微信好友
              <video id="orderVideo6" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              商品可一一加入进货单，一键直接浏览
              <video id="orderVideo7" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              付款方式自选：可微信，可余额支付，可货到付款
              <video id="orderVideo8" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              会员一键接入客服，多名客服可微信接收咨询
              <video id="orderVideo9" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
            </dt>
            <dt>
              批发商和采购商提前绑定账号<br />商品到货，一键直接进货，自动新建档案
            </dt>
          </dl>
          <div class="video_mask" @click="playVideo()"></div>
        </div>
      </div>

      <div class="section section_12" id="autoReport">
        <div class="content">
          <div class="title">店教授自动报货</div>
          <div class="divider_theme"></div>
          <div class="sub_title">提前设定模板，快速发起订货</div>
          <div class="auto_report_pager">
            <div class="page show">
              <div class="banner">
                <span class="color_theme">01</span>
                <span class="detail_title">云后台设定订货模板</span>
                <span class="detail_describe"
                  >设置好商品"销量阈值"，"起订量"和"订货基数"，门店不用管理库存，也可实现"自动报货"</span
                >
              </div>
            </div>
            <div class="page highlight">
              <video id="reportVideo" preload="">
                <source src="" type="video/mp4" />
                您的浏览器不支持 video 元素。
              </video>
              <div class="video_mask" @click="playVideo()"></div>
              <p>
                移动端订货按累计销量从高到低进行排序<br />
                累计销量超过销售阈值的商品显示红色提醒进货<br />
                自动匹配进货数量，按设置的起订基数增加购买数量<br />
                支持线上支付、货到付款等支付方式<br />
              </p>
              <div class="banner">
                <span class="color_theme">02</span>
                <span class="detail_title">手机端自动报货</span>
                <span class="detail_describe"
                  >当商品"自上次订货到现在为止"销量达到"销量阈值"时。收银前端自动提醒订货</span
                >
              </div>
            </div>

            <div
              class="btn_left"
              @mouseover="autoChangePage(event.target)"
            ></div>
            <div
              class="btn_right"
              @mouseover="autoChangePage(event.target)"
            ></div>
          </div>
        </div>
      </div>

      <div class="section section_13" id="openApi">
        <div class="content">
          <div class="title">开放API</div>
          <div class="divider_theme"></div>
          <div class="sub_title">
            一个系统即能管理所有经营业务，降低大规模数据迁移带来的丢失和错乱风险
          </div>
          <dl class="marked_list">
            <dt>快速接入会员系统、财务软件、货流信息至店教授后台</dt>
            <dt>支持打通各大供应链、WMS、TMS系统</dt>
            <dt>一键实现门店订货、供应商自动接单和分拣配送等操作</dt>
            <dt>轻松接入自己的系统</dt>
          </dl>
        </div>
      </div>

      <div class="section section_14" id="dataReport">
        <div class="data_report_pager">
          <div class="page show">
            <div class="content">
              <div class="title" style="line-height: 50px">
                店教授数据分析<br />书写营业秘籍
              </div>
              <div class="sub_title">
                店教授联手国际知名咨询公司A.S.Louken潜心研发，从营运、销售、会员、商品等<br />
                多个维度为你打造个人数据分析师。从科学数据出发，帮助店家进行科学决策
              </div>
            </div>
          </div>
          <div class="page">
            <div class="content">
              <div class="title">云后台数据报表</div>
              <div class="sub_title">智能数据分析，助力门店运营决策</div>
            </div>
          </div>
          <div class="page">
            <div class="content">
              <div class="title">我的店铺</div>
              <div class="sub_title">
                手机即可查看门店各项数据，销售情况实时掌握
              </div>
            </div>
          </div>
          <dl class="dots">
            <dt class="dot highlight" @mouseover="(e) => changePage(0,e)"></dt>
            <dt class="dot" @mouseover="(e) =>  changePage(1,el)"></dt>
            <dt class="dot" @mouseover="(e) =>  changePage(2,e)"></dt>
          </dl>
        </div>
      </div>

      <div class="section section_15">
        <div class="content">
          <div class="title" style="line-height: 50px">
            开始体验<br />店教授零售收银
          </div>
          <div class="divider_theme"></div>
          <div class="description">注册&登录就可体验店教授云后台管理</div>
          <div class="flex">
            <a class="btn"
              >电脑&平板浏览者点此注册店教授账号</a
            >
            <a class="btn"> 
              手机浏览者点此注册店教授账号
            </a>
          </div>
          <div class="description">下载［店教授收银］软件，开始收银场景</div>
          <div class="flex">
            <a class="btn"> Windows电脑用户点此下载 </a>
            <a class="btn">Android手机用户点此下载</a>
            <a class="btn">iOS平板用户点此下载</a>
            <a class="btn">iOS手机用户点此下载</a>
            <a class="btn">Android平板用户点此下载</a>
            <a class="btn">更多下载信息</a>
          </div>
          <div class="divider_gray" style="margin: 140px 0px 76px"></div>
          <div class="flex">
            <div class="title" style="line-height: 50px">
              店教授零售<br />百万门店的信任之选
            </div>
            <div
              class="divider_gray_vertial"
              style="margin: 35px 166px 45px 80px; height: 320px"
            ></div>
            <div class="case"></div>
          </div>
          <div class="divider_gray" style="margin: 74px 0px 120px"></div>
          <div class="title" style="line-height: 50px">
            新店开张<br />推荐知名便利店同款软硬件套餐
          </div>
          <div class="flex">
            <div class="products"></div>
            <div class="qr_code">详情请扫码咨询 店教授专业顾问</div>
          </div>
          <div class="divider_gray" style="margin: 120px 0px"></div>
          <div class="title">
            马上注册<br />立得神秘礼包
            <a class="btn f24">免费注册</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 智慧生鲜 -->
    <div class="animated bounceInLeft" v-if="tabIndex == 2">
      <fresh-view></fresh-view>
    </div>

    <!-- 智慧茶饮 -->
    <div class="animated bounceInLeft" v-if="tabIndex == 3">
      <tea-view></tea-view>
    </div>
  </div>
</template>

<script>
import "../../assets/css/cloud/common.css";
import "../../assets/css/common/common.css";
import "../../assets/css/common/case.css";
import "../../assets/css/plan/retail.css";
import fresh from '../../components/fresh.vue';
import tea from '../../components/tea.vue'
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
// import '../../assets/js/jquery/jquery.js'
// import '../../assets/js/retail.js'
export default {
  components:{
     "fresh-view":fresh,
     "tea-view":tea,
     Swiper
  },
  data() {
    return {
      tabIndex: 0,
      tabList: ["智慧餐厅", "智慧零售", "智慧生鲜", "智慧茶饮"],
      list1: [
        {
          id: 1,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_1.png"),
          tit: "立式自助收音机",
          cont_1: "自助操作，省时省力",
          cont_2: "首屏广告位，营销更容易",
          cont_3: "全面打造前厅应用场景数字化",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_2.png"),
          tit: "立式自助收音机",
          cont_1: "多模块应用，人性化操作",
          cont_2: "聚合支付，化繁为简",
          cont_3: "帮助门店提效降本，高效运营",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_3.png"),
          tit: "厨房显示系统",
          cont_1: "实时同步，协同高效",
          cont_2: "出品更快，传菜更准",
          cont_3: "打造无纸化后厨时代",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_4.png"),
          tit: "排队",
          cont_1: "可实现线上、线下取号",
          cont_2: "支持查看排队进度、智能叫号",
          cont_3: "预点单功能，提升顾客到店就餐体验",
        },
        {
          id: 5,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_5.png"),
          tit: "云蓝收银智能APP",
          cont_1: "数据赋能，管理智能",
          cont_2: "功能丰富，应用广泛",
          cont_3: "现随时随地高效管理",
        },

        {
          id: 6,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_6.png"),
          tit: "商家管理后台",
          cont_1: "数据可视化，报表多样化",
          cont_2: "便捷配置，轻松上手",
          cont_3: "助力管理者精选运营门店",
        },

        {
          id: 7,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_7.png"),
          tit: "外卖",
          cont_1: "对接第三方外卖平台和自营外卖",
          cont_2: "智能接单，聚合配送",
          cont_3: "",
        },
        {
          id: 8,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_8.png"),
          tit: "桌面式自助点单/手持收银",
          cont_1: "多样化点单，编辑收银",
          cont_2: "有效释放收银台压力",
          cont_3: "",
        },
        {
          id: 9,
          imgUrl: require("../../assets/images/catering/zuihui_1/project_9.png"),
          tit: "扫码点单",
          cont_1: "无需排队，自助下单",
          cont_2: "节省人力成本，缩短用餐时间",
          cont_3: "有效提升门店整体翻台率",
        },
      ],
      list2: [
        {
          id: 1,
          imgUrl: require("../../assets/images/catering/zuihui_2/project_1.png"),
          tit: "商品、价格、库存、报表、会员、营销和人员管理等，总部统一管理",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/catering/zuihui_2/project_2.png"),
          tit: "货品管理，实时订单管理，商品行情分析，供应商管理等",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/catering/zuihui_2/project_3.png"),
          tit: "仓库管理，进销存管理；智能补货，配送管理；商品分析，调拨管理等",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/catering/zuihui_2/project_4.png"),
          tit: "对接饿了么外卖、美团外卖，微信、支付宝双端小程序，自营外卖0佣金",
        },
        {
          id: 5,
          imgUrl: require("../../assets/images/catering/zuihui_2/project_5.png"),
          tit: "对接天猫、淘宝等电商平台，线上售卖半成品等 ",
        },
      ],
      list3: [
        {
          id: 1,
          imgUrl: require("../../assets/images/catering/zuihui_3/project_1.png"),
          tit: "智能称重收银一体机",
          cont_1: "自助称重，智能收银，",
          cont_2: "自动接单，外卖接单，",
          cont_3: "会员管理。",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/catering/zuihui_3/project_2.png"),
          tit: "数据大屏",
          cont_1: " 今日商品售价，热销种类、商品热卖",
          cont_2: "档口排行、口位置分布市场公告档",
          cont_3: "新闻滚动播报",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/catering/zuihui_3/project_3.png"),
          tit: "智能收银机",
          cont_1: "智能点单收银",
          cont_2: "聚合支付，会员管理，营销活动",
          cont_3: "",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/catering/zuihui_3/project_4.png"),
          tit: "线上外卖",
          cont_1: "对接美团外卖、饿了么外卖、微信",
          cont_2: "支付宝双端小程序，自营外卖0佣金",
          cont_3: "",
        },
      ],
      list4: [
        {
          id: 1,
          imgUrl: require("../../assets/images/catering/zuihui_4/project_1.png"),
          tit: "下属门店管理",
          cont_1: "详细门店经营报表",
          cont_2: "经营状况一目了然",
          cont_3: "",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/catering/zuihui_4/project_2.png"),
          tit: "库存管理",
          cont_1: "仓储管理，进销存管理",
          cont_2: "智能补货，配送管理、商品分析",
          cont_3: "门店调拨，采购分析等",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/catering/zuihui_4/project_3.png"),
          tit: "总部管理",
          cont_1: "商品、价格、库存、报表",
          cont_2: "会员、营销和人员管理等，总部统一管理",
          cont_3: "",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/catering/zuihui_4/project_4.png"),
          tit: "聚合外卖",
          cont_1: "对接美团外卖、饿了么外卖",
          cont_2: "微信、支付宝双端小程序、自营外卖0佣金",
          cont_3: "",
        },
      ],
    };
  },
  methods: {
    handleTba(i) {
      this.tabIndex = i;
    },
    changeTab(index,event) {
      console.log('111',event);
			let target = event.target;
			let context = event.target.parentElement;
			context.querySelector(".title_selected").classList.remove("title_selected");
			target.classList.add("title_selected");
			context.querySelector(".content_selected").classList.remove("content_selected");
			context.querySelector(".tab_content_" + index).classList.add("content_selected");
		}
  },
};
let isStopAnimaOnce = false;
		let isStopAnima = false;
		let isAutoPlay = true;
		let count = 0;

		(function () {
			setInterval(function () {
				if (isStopAnima) return;
				if (isStopAnimaOnce) {
					isStopAnimaOnce = false;
					return;
				}
				count++;
				isAutoPlay = isVisible($('.section_11')[0]);
				[].forEach.call($(".flow_list > .highlight"), autoChangeHighlight);
				[].forEach.call($(".dots > .highlight"), autoChangePage);
			}, 3000);

			[].forEach.call($(".flow_list > dt"), function (element) {
				element.addEventListener("click", changeHighlight);
			});

			[].forEach.call($("video"), function (element) {
				element.addEventListener("play", hideMask);
				element.addEventListener("pause", showMask);
				element.addEventListener("ended", showMask);
			});
		})()

		function isVisible(element) {
			let visibleRangeTop = window.scrollY,
				visibleRangeBottom = window.scrollY + window.innerHeight;
			let elementTop = element.offsetTop,
				elementBottom = element.offsetTop + element.clientHeight;
			return (elementTop > visibleRangeTop && elementTop < visibleRangeBottom) ||
				(elementBottom > visibleRangeTop && elementBottom < visibleRangeBottom) ||
				(elementTop < visibleRangeTop && elementBottom > visibleRangeBottom);
		}

		function autoChangeHighlight(element) {
			element.classList.remove("highlight");
			let nextElement = element.nextElementSibling ?
				element.nextElementSibling :
				element.parentElement.firstElementChild;
			nextElement.classList.add("highlight");

			if (nextElement.querySelector("video") && isAutoPlay) {
				if (isAutoPlay) {
					nextElement.querySelector("video").play();
				} else {
					nextElement.querySelector("video").pause();
				}
			}
		}

		function changeHighlight(event) {
			isStopAnimaOnce = true;
			event.target.parentElement.querySelector(".highlight").classList.remove("highlight");
			event.target.classList.add("highlight");

			[].forEach.call($("video"), function (element) {
				if (element.paused || element.ended) return;
				element.pause();
			})
		}

		function autoChangePage(element) {
			let pageShowedElement = element.parentElement.parentElement.querySelector(".show");
			pageShowedElement.classList.remove("show");
			let nextElement = pageShowedElement.nextElementSibling;
			if (nextElement && nextElement.classList.contains('page')) {
				nextElement.classList.add("show");
				if (nextElement.querySelector("video")) {
					nextElement.querySelector("video").play();
				}
			} else {
				pageShowedElement.parentElement.firstElementChild.classList.add("show");
				if (pageShowedElement.parentElement.querySelector("video")) {
					pageShowedElement.parentElement.querySelector("video").pause();
				}
			}
			autoChangeHighlight(element);
		}
    function changePage(index, event) {
			isStopAnimaOnce = true;
			let pagerElement = event.target.parentElement.parentElement;
			pagerElement.querySelector(".show").classList.remove("show");
			pagerElement.querySelectorAll(".page")[index].classList.add("show");
			changeHighlight(event);
		}
    function playVideo() {
			let videoElement = event.target.parentElement.querySelector(".highlight > video");
			if (videoElement.paused || videoElement.ended) {
				videoElement.play();
			} else {
				videoElement.pause();
			}
		}

		function showMask() {
			isStopAnima = false;
			let maskElem = event.target.parentElement.parentElement.parentElement.querySelector(".hide_mask");
			if (maskElem) maskElem.classList.remove("hide_mask");
		}

		function hideMask() {
			isStopAnima = true;
			let maskElem = event.target.parentElement.parentElement.parentElement.querySelector(".video_mask");
			if (maskElem) maskElem.classList.add("hide_mask");
		}
</script>

<style scoped>
.catering {
  min-height: 100vh;
  background-color: #f5f5f5;
}
.banner_contB {
  left: 150px;
  top: 0;
  width: 100%;
}
.tabbarBox {
  height: 70px;
  line-height: 70px;
}
.list_1 .item {
  height: 200px;
  width: 525px;
  margin-right: 50px;
  margin-bottom: 40px;
  padding: 12px 40px;
}
.list_1 .item:nth-child(2n) {
  margin-right: 0;
}
.item:hover {
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.list_1 .item:last-child {
  width: 1100px;
  margin-right: 0;
  justify-content: center;
}
.benchmark {
  height: 370px;
  background: linear-gradient(90deg, #0b7ee9 0%, #75bcff 100%);
}
.benchmark_tip {
  display: inline-block;
  padding: 0 5px;
  color: #0b7ee9;
}
.list_2 .item {
  padding: 10px 25px;
  margin-bottom: 30px;
}

.list_3 .item {
  width: 525px;
  margin-right: 50px;
  margin-bottom: 40px;
}
.list_3 .item:nth-child(2n) {
  margin-right: 0;
}
.list_3 .cont_box {
  height: 180px;
}

.section-2 {
  height: 693px;
  background-image: url("../../assets/images/catering/section_2.jpg");
}
.section-2 .content .text {
  position: absolute;
  width: 100%;
  text-align: left;
  top: 220px;
  left: 80px;
}
.section-3 {
  height: 634px;
  background-image: url("../../assets/images/catering/section_3.jpg");
}
.section-3 .content .text {
  position: absolute;
  width: 600px;
  top: 190px;
  left: 680px;
}
.section-4 {
  height: 702px;
  background-image: url("../../assets/images/catering/section_4.jpg");
}
.section-4 .content .text {
  position: absolute;
  width: 600px;
  top: 170px;
  left: 80px;
}
.section-4 .content .text .text-4 {
  position: absolute;
  font-size: 18px;
  margin: 20px 0 0 0;
}
.section-5 {
  height: 1049px;
  background-image: url("../../assets/images/catering/section_5.jpg");
}
.section-5 .content .text {
  position: absolute;
  width: 100%;
  top: 135px;
  text-align: center;
}
.section-5 .content .text .text-4 {
  font-size: 18px;
  margin: 40px 0 0 0;
}
.section-6 {
  height: 702px;
  background-image: url("../../assets/images/catering/section_6.jpg");
}
.section-6 .content .text {
  position: absolute;
  width: 600px;
  text-align: left;
  top: 190px;
  left: 80px;
}
.section-7 {
  height: 704px;
  background-image: url("../../assets/images/catering/section_7.jpg");
}
.section-7 .content .text {
  position: absolute;
  width: 600px;
  top: 210px;
  left: 680px;
}
.section-8 {
  height: 699px;
  background-image: url("../../assets/images/catering/section_8.jpg");
}
.section-8 .content .text {
  position: absolute;
  width: 600px;
  text-align: left;
  top: 180px;
  left: 80px;
}
.section-9 {
  height: 634px;
  background-image: url("../../assets/images/catering/section_9.jpg");
}
.section-9 .content .text {
  position: absolute;
  width: 600px;
  top: 155px;
  left: 680px;
}
.section-10 {
  height: 700px;
  background-image: url("../../assets/images/catering/section_10.jpg");
}
.section-10 .content .text {
  position: absolute;
  width: 600px;
  top: 210px;
  left: 80px;
}
.section-11 {
  height: 633px;
  background-image: url("../../assets/images/catering/section_11.jpg");
}
.section-11 .content .text {
  position: absolute;
  width: 100%;
  top: 190px;
  text-align: center;
}
.section-13 {
  height: 787px;
  background-image: url("../../assets/images/catering/section_13.jpg");
}
.section-13 .content .text {
  position: absolute;
  width: 100%;
  top: 200px;
  text-align: center;
}
.section-13 .content .text .text-1 {
  position: absolute;
  font-size: 48px;
  width: 100%;
  top: -110px;
}
.section-13 .content .text .text-2 {
  position: absolute;
  font-size: 15px;
  left: 445px;
}
.section-13 .content .text .text-2-1 {
  top: 18px;
}
.section-13 .content .text .text-2-2 {
  top: 58px;
}
.section-13 .content .text .text-2-3 {
  top: 95px;
}
.section-13 .content .text .text-2-4 {
  top: 133px;
}
.section-13 .content .text .text-2-5 {
  top: 172px;
}
.section-13 .content .text .text-2-6 {
  top: 209px;
}
.section-13 .content .text .text-2-7 {
  top: 249px;
}
.section-13 .content .text .text-2-8 {
  top: 289px;
}
.section-13 .content .text .text-3 {
  position: absolute;
  font-size: 15px;
  left: 687px;
}
.section-13 .content .text .text-3-1 {
  top: 18px;
}
.section-13 .content .text .text-3-2 {
  top: 58px;
}
.section-13 .content .text .text-3-3 {
  top: 95px;
}
.section-13 .content .text .text-3-4 {
  top: 133px;
}
.section-13 .content .text .text-3-5 {
  top: 172px;
}
.section-13 .content .text .text-3-6 {
  top: 209px;
}
.section-13 .content .text .text-3-7 {
  top: 249px;
}
.section-13 .content .text .text-3-8 {
  top: 289px;
}
.section-13 .content .text .experience {
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  display: block;
  margin: 370px auto 0 auto;
  background-color: #ff6727;
  letter-spacing: 2px;
  border-radius: 2px;
}
.section-13 .content .text .text-4 {
  font-size: 18px;
  margin: 40px 0 0 0;
}
</style>