<template>
  <div>
    <div class="page-2">
      <div class="page-2__text-1">店教授茶饮核心功能</div>
      <div class="page-2__text-2">
        刚需功能，一个不落&nbsp; &nbsp; 挖掘行业更深层次需求场景
      </div>
      <div class="page-2__text-3">聚合小程序</div>
      <div class="page-2__text-4">
        <span>移动收银</span><span>聚合外卖</span><span>全渠道会员</span>
      </div>
      <div class="page-2__text-5">
        <span>多样化营销</span><span>KDS制作屏</span><span>效期管理</span>
      </div>
      <div class="page-2__text-6">
        <span>原料BOM管理</span><span>智慧供应链</span><span>数据中心</span>
      </div>
    </div>
    <div class="page-3">
      <div class="page-3__text-1">POS多场景应用</div>
      <div class="page-3__text-2">
        简洁收银界面
        <br />
        流程化繁为简，减少页面跳转
        <br />
        员工操作更省心，顾客取餐更省时
      </div>
      <div class="page-3__text-3">
        <p>全平台应用，选择更自主</p>
        <p>软件适用于window、安卓、iOS等操作系统</p>
      </div>
      <div class="page-3__text-4">
        <p>多设备适配，成本可把控</p>
        <p>收银机、pc、平板、手机皆可收银</p>
      </div>
    </div>
    <div class="page-4">
      <div class="page-4__text-1">
        人性化交互界面
        <br />
        提高点单效率
      </div>
      <div class="page-4__text-2">
        <p>图文展示更直观</p>
        <p>图文展示，直观更高效</p>
      </div>
      <div class="page-4__text-3">
        <p>分布式口味弹窗</p>
        <p>弹窗选择规格/冰度/甜度/加料等</p>
      </div>
      <div class="page-4__text-4">
        <p>默认推荐口味</p>
        <p>可设置可选/必选和默认口味</p>
      </div>
      <div class="page-4__text-5">
        <p>简化点单流程</p>
        <p>智慧点单，傻瓜式操作，提高员工效率</p>
      </div>
    </div>
    <div class="page-5">
      <div class="page-5__text-1">自定义小票模板，满足你的个性需求</div>
      <div class="page-5__text-2">
        灵活配置小票、标签、效期模板
        ，更自由&nbsp;&nbsp;&nbsp;&nbsp;规格、内容、字体、字号自定义设置，更灵活
      </div>
      <dic class="page-5__text-3">
        <span>收银标签</span>
        <span>配送小票</span>
        <span>收银小票</span>
        <span>物料效期标签</span>
      </dic>
    </div>
    <div class="page-6">
      <div class="page-6__text-1">茶饮聚合小程序</div>
      <div class="page-6__text-2">
        主流平台小程序汇集一个系统，多流量，轻管理
      </div>
      <div class="page-6__text-3">
        <span>微信小程序</span>
        <span>抖音小程序</span>
        <span>支付宝小程序</span>
      </div>
      <div class="page-6__text-4">
        聚合主流流量平台小程序，一个后台管控，
        <br />
        实现产品通、会员通、营销通，
        <br />
        为后期更高效的会员管理与营销变现做支撑。
      </div>
    </div>
    <div class="page-7">
      <div class="page-7__text-1">茶饮智慧供应链</div>
      <div class="page-7__text-2">
        系统与供应链紧密结合，一键订货，智能分仓，门店一键收货入库
        实现门店进、销、存一体化
      </div>
      <div class="page-7__text-3">
        <div>
          <p>智能订单</p>
          <p>系统分析门店历史数据 智能计算出合理订货值 避免无效库存</p>
        </div>
        <div>
          <p>智能采购</p>
          <p>
            当低于设置的安全库存 系统立即自动生成采购单 并通知采购专员补充库存
          </p>
        </div>
        <div>
          <p>分仓发货</p>
          <p>
            全国仓/区域仓
            <br />
            两种分仓模式可选
          </p>
        </div>
        <div>
          <p>总部强配方案</p>
          <p>
            总部分析门店销售数据
            <br />
            即可代门店下单
            <br />
            为门店进行强配货
          </p>
        </div>
      </div>
      <div class="page-7__text-4">
        <div>
          <p>多级订货</p>
          <p>
            不同体系或级别的门店
            <br />
            总部可设置不同的价格
          </p>
        </div>
        <div>
          <p>多种支付方式</p>
          <p>
            解決门店订货难
            <br />
            品牌收款难的问題
          </p>
        </div>
        <div>
          <p>多场景结算体系</p>
          <p>
            全场景结算体系
            <br />
            门店就近调货场景
            <br />
            门店订货场景...
          </p>
        </div>
        <div>
          <p>监控门店私采报表</p>
          <p>
            查看各门店各类报表
            <br />
            在合理范围内监控
          </p>
        </div>
      </div>
    </div>
    <div class="page-8">
      <div class="page-8__text-1">数据报表</div>
      <div class="page-8__text-2">
        拒绝拍脑袋决策，用数据管控门店
        <br />
        近百种数据报表 ，100%覆盖门店运营全流程
      </div>
    </div>
    <div class="page-9">
      <div class="page-9__text-1">店教授茶饮矩阵</div>
      <div class="page-9__text-2">
        系统与供应链紧密结合，一键订货，智能分仓
        <br />
        门店一键收货入库，实现门店进、销、存一体化
      </div>
      <div class="page-9__text-3">
        <div>
          <div>小程序<span>（聚合点单/商城/礼品卡）</span></div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />聚合小程序 (微信 | 抖音 | 支付宝)
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />高度灵活自定义装修
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />微信 | 支付宝 | 抖音 平台资源支持
            </p>
          </div>
        </div>
        <div>
          <div>POS<span>（windows/安卓/IOS）</span></div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />全平台应用
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />不挑设备，通用设各可支持
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />打通外卖台
            </p>
          </div>
        </div>
        <div>
          <div>供应链/订货系统</div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />门店快捷订货
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />订单直达仓库/供应商
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />关联门店BOM表，数据一目了然
            </p>
          </div>
        </div>
      </div>
      <div class="page-9__text-4">
        <div>
          <div>智能KDS系统</div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />私有化部署
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />配方与制作分离，商业机密不外泄
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />上手快，人员培训1周内搞定
            </p>
          </div>
        </div>
        <div>
          <div>招商加盟系统</div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />一键上架招商加盟小程序
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />小程序查看招商信息,沟通意向客户
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />自有客户留存系统,信息不外流
            </p>
          </div>
        </div>
        <div>
          <div>巡店系统</div>
          <div>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />远程巡店/视频巡店/任务管理
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />培训教学/考试考核/问题整改
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              />关联中层管理和门店的KPI
            </p>
          </div>
        </div>
      </div>
      <div class="page-9__text-5">
        <div class="page-9__text-5__body">
          <div>B1<span style="color: #fff">（数据中心 ）</span></div>
          <div class="page-9__text-5__content">
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              /><span
                >招向看板/业绩目标/会员分析/外卖 分析/成本分析/活动分析等综合型
                人货场客维度对比,决策有据可依</span
              >
            </p>
          </div>
        </div>
        <div class="page-9__text-5__body">
          <div>生产ERP</div>
          <div class="page-9__text-5__content">
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              /><span style="width: 16vw"
                >为中央局厨房房、生产中心打造的生产系统</span
              >
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              /><span>与供应链完关结合</span>
            </p>
          </div>
        </div>
        <div class="page-9__text-5__body">
          <div>云财务</div>
          <div class="page-9__text-5__content">
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              /><span>完美打通门店销售系统、供应链系统和生产系统</span>
            </p>
            <p>
              <img
                src="../../src/assets/images/catering/tea/check.png"
              /><span>自动记账，自动生成凭证</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-10">
      <div class="page-10__text-1">超3000</div>
      <img
        class="page-10__text-2"
        src="../../src/assets/images/catering/tea/形状45@3x.png"
      />
      <div class="page-10__text-3">品牌的共同选择</div>
      <div class="page-10__text-4">
        每一个品牌的信赖，都将是我们认真做好产品的动力
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/css/plan/tea/tea.css'
export default {};
</script>

<style scoped>
</style>